import { CompanyFeaturesEnum } from '../enums';

import { useUserInfo } from './useUserInfo';

interface IUseCompanyFeatures {
  CompanyFeaturesEnum: typeof CompanyFeaturesEnum;
  isCompanyFeatureEnabled: (feature: CompanyFeaturesEnum) => boolean;
  isChangeMonitoringEnabled: () => boolean;
}

export const useCompanyFeatures = (): IUseCompanyFeatures => {
  const { hookWhoAmI } = useUserInfo();

  const isCompanyFeatureEnabled = (feature: CompanyFeaturesEnum): boolean => {
    return hookWhoAmI?.companyFeatures?.includes(feature) ?? false;
  };

  return {
    CompanyFeaturesEnum,
    isCompanyFeatureEnabled,
    isChangeMonitoringEnabled: () => isCompanyFeatureEnabled(CompanyFeaturesEnum.CHANGE_MONITORING),
  };
};
