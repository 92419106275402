import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  AppWrapper,
  Button,
  ErrorBanner,
  Modal,
  PageTitle,
  Pagination,
  RecordsDropdown,
  Table,
  Tabs,
  Toggle,
} from 'components';
import { useModal } from 'hooks';
import React from 'react';
import { DefaultPropsType } from 'types';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150, path } from 'utils';

import { columns } from './contracts';
import { PUBLISHER_SEARCH } from './enums';
import { usePublisherSearch } from './hooks';
import UpdateCustomListModal from './Modal';
import * as Styled from './styles';

type FintelCheckPublisherSearchProps = DefaultPropsType;

const FintelCheckPublisherSearch = ({ permissionsCodeList = [] }: FintelCheckPublisherSearchProps): JSX.Element => {
  const [modal, setModal] = useModal(false);
  const { hookIsReadOnlyList, ...hook } = usePublisherSearch(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner isOpen={hook.monitoringErrors.length > 0} message={hook.monitoringErrors} />
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookLoading} loadingText="Loading Publishers">
          {path.fintelCheckPublisherSearch.name}
        </PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <>
            <Styled.ToggleLabelStyled>Enable Tracking Urls</Styled.ToggleLabelStyled>
            <Toggle
              checked={hook.trackingUrlMonitoringToggle}
              onChange={hook.setTrackingUrlMonitoringToggle}
              disabled={isReadOnly || hook.hookLoading}
            />
          </>
          <Styled.ButtonStyled onClick={setModal} disabled={hook.hookDisableButton || isReadOnly}>
            {PUBLISHER_SEARCH.BUTTONS.UPDATE}
          </Styled.ButtonStyled>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>
      <Tabs
        tabNames={['Publishers']}
        elements={[
          <>
            <Styled.FiltersStyled>
              <Styled.InputTextStyled
                placeholder="Search by Company Name, ID, URL"
                type="text"
                value={hook.hookSearch}
                onChange={hook.hookSetSearch}
                faIcon={faSearch}
              />

              <Styled.ExtendedFiltersStyled>
                <Styled.FilterByLabelStyled>{PUBLISHER_SEARCH.FILTER}</Styled.FilterByLabelStyled>

                <Styled.SelectStyled
                  name="category"
                  placeholder="By Category"
                  selected={hook.hookCategory}
                  onChange={hook.hookSetCategory}
                  options={hook.hookCategoryOptions}
                  isLoading={hook.hookLoading}
                />
                <Styled.SelectStyled
                  name="location"
                  placeholder="By location"
                  selected={hook.hookLocation}
                  onChange={hook.hookSetLocation}
                  options={hook.hookLocationOptions}
                  isLoading={hook.hookLoading}
                />

                <Button theme="text-only" onClick={hook.hookResetFilters}>
                  {PUBLISHER_SEARCH.BUTTONS.CLEAR}
                </Button>
              </Styled.ExtendedFiltersStyled>
            </Styled.FiltersStyled>
            <Styled.RecordsDropdownWrapper>
              <RecordsDropdown
                selected={hook.hookRecords}
                onChange={hook.hookSetRecords}
                options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
                currentPage={hook.hookCurrentPage}
                totalRecords={hook.hookTotalRecords}
              />
            </Styled.RecordsDropdownWrapper>

            <Table
              columns={columns(isReadOnly)}
              data={hook.hookData}
              setData={hook.hookSetData}
              headerCheckBox={hook.hookHeader}
              handleHeaderCheckBox={hook.hookSetHeader}
              setModalInfo={hook.hookUpdateList}
              isLoading={hook.hookLoading}
              sortColumn={hook.hookSortColumn}
              onSort={hook.hookHandleSort}
              onRowClick={hook.hookOnRowClick}
            />
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookCurrentPage}
              onPageChange={hook.hookOnPageChange}
            />

            <Modal isOpen={modal}>
              <UpdateCustomListModal
                setIsHeader={hook.hookSetIsHeader}
                publisherList={hook.hookPublisherList}
                removePublisher={hook.hookRemovePublisher}
                updateList={hook.hookUpdateCustomList}
                closeFn={setModal}
              />
            </Modal>
          </>,
        ]}
      />
    </AppWrapper>
  );
};

export default FintelCheckPublisherSearch;
