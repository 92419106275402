import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent } from 'react';

import { Button, Modal } from '../../../../../../../../components';
import { RULE_MANAGER } from '../../../../enums';
import AddCustomTextComponent from '../../../AddCustomTextComponent';
import RuleStatusSettingsComponent from '../../../RuleStatusSettingsComponent';
import { RuleStatusSettingsType } from '../../../RuleStatusSettingsComponent/enums';
import { STEP_TWO_MODAL } from '../../enums';
import * as Styled from '../../styles';
import { CustomTextListType, IndexedObject } from '../../types';

type AddRuleStepTwoProps = {
  isOpen: boolean;
  backButtonHandler: () => void;
  customTextList: CustomTextListType[];
  stepTwoErrors: IndexedObject;
  onBlurCheck: () => void;
  updateCustomTextValue: (id: number, value: ChangeEvent<HTMLInputElement>) => void;
  removeCustomTextById: (id: number) => void;
  cancelButtonHandler: () => void;
  navigateModalThree: () => void;
  addNewCustomText: () => void;
  canContinueTwo: boolean;
  canAddMoreCustomText: boolean;
  uniqueErrors: string;
  ruleStatusSettings: RuleStatusSettingsType;
  setRuleStatusSettings: (value: RuleStatusSettingsType) => void;
};

export const AddRuleStepTwoModal = ({
  isOpen,
  backButtonHandler,
  uniqueErrors,
  customTextList,
  addNewCustomText,
  stepTwoErrors,
  onBlurCheck,
  removeCustomTextById,
  canAddMoreCustomText,
  updateCustomTextValue,
  cancelButtonHandler,
  navigateModalThree,
  canContinueTwo,
  ruleStatusSettings,
  setRuleStatusSettings,
}: AddRuleStepTwoProps): JSX.Element => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>
        <Styled.BackIconStyled onClick={backButtonHandler}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Styled.BackIconStyled>
        {STEP_TWO_MODAL.TITLE}
      </Styled.PageTitleStyled>
      <Styled.UniqueValuesMessage>{uniqueErrors}</Styled.UniqueValuesMessage>

      <Styled.RuleStatusWrapper>
        <Styled.RuleStatusLabel>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS}
          <Styled.RequiredStyled>*</Styled.RequiredStyled>
        </Styled.RuleStatusLabel>
        <Styled.RuleStatusDescription>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS_SETTINGS_DESCRIPTION}
        </Styled.RuleStatusDescription>
        <RuleStatusSettingsComponent
          ruleStatusSettings={ruleStatusSettings}
          setRuleStatusSettings={setRuleStatusSettings}
          errors={stepTwoErrors}
        />
      </Styled.RuleStatusWrapper>

      <AddCustomTextComponent
        textList={customTextList}
        inputLabelText={STEP_TWO_MODAL.CUSTOM_TEXT.LABEL}
        inputTooltipText={STEP_TWO_MODAL.CUSTOM_TEXT.TOOLTIP}
        errors={stepTwoErrors}
        onBlur={onBlurCheck}
        onChange={updateCustomTextValue}
        removeTextHandler={removeCustomTextById}
        addTextHandler={addNewCustomText}
        isAddButtonEnabled={canAddMoreCustomText}
        addButtonText={STEP_TWO_MODAL.ADD_CUSTOM_TEXT}
      />

      <Styled.ModalFooterStyled>
        <Styled.PageCounterStyled>{STEP_TWO_MODAL.STEP_COUNTER}</Styled.PageCounterStyled>
        <Styled.NavButtonsWrapperStyled>
          <Button theme="secondary" onClick={cancelButtonHandler} width="100px">
            {STEP_TWO_MODAL.CANCEL_BUTTON}
          </Button>
          <Button theme="primary" onClick={navigateModalThree} disabled={!canContinueTwo} width="120px">
            {STEP_TWO_MODAL.CONTINUE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
