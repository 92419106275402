import {
  faCircleCheck,
  faCircleXmark,
  faChartPie,
  faRotateRight,
  faCircleExclamation,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingDots } from 'components';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import {
  CloseChecksTypes,
  RULE_SUMMARY_DASHBOARD,
} from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/enums';
import useRuleSummaryDashboard from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/hooks/useRuleSummaryDashboard';
import { camelCaseToSentenceCase } from 'pages/Merchants/FintelCheck/FintelCheckChangeHistory/contracts';
import React, { useEffect } from 'react';
import { colors } from 'styles/theme';
import { toRem } from 'utils';

import * as Styled from './styles';

const RulesSummaryDashboard = ({ isMonitoringReport = true }: { isMonitoringReport: boolean }): JSX.Element | null => {
  const hook = useRuleSummaryDashboard({ isMonitoringReport });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
  const [closeCheckContext, setCloseCheckContext] = React.useState<CloseChecksTypes | ''>('');

  useEffect(() => {
    if (!isConfirmationModalOpen) {
      // using timeout to avoid chaging the string before the modal closes
      setTimeout(() => {
        setCloseCheckContext('');
      }, 250);
    }
  }, [isConfirmationModalOpen]);

  /* Calculates the total amount of passed checks with the exception of Closed and Total */
  const extractTotalChecksAmount = (isPassed: boolean): number => {
    if (!hook.dashboardData) return 0;
    if (isPassed) {
      return (
        hook.dashboardData.passed.firstCheck +
        hook.dashboardData.passed.awaitingExternalAction +
        hook.dashboardData.passed.awaitingInternalAction +
        hook.dashboardData.passed.newFinding
      );
    }
    return hook.dashboardData.incomplete.open + hook.dashboardData.notApplicable.open;
  };

  const renderChecksContent = (): JSX.Element => (
    <Styled.BoxStyled background={colors.color39}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon icon={faChartPie} style={{ marginRight: toRem(12), fontSize: toRem(28) }} />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.checks.total ?? 0} {RULE_SUMMARY_DASHBOARD.CHECKS}
            </Styled.BoxTitleStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            <Styled.BoxContentStyled>
              {hook.dashboardData.checks.urlsMonitored} {RULE_SUMMARY_DASHBOARD.URLS_MONITORED}
            </Styled.BoxContentStyled>
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderPassedContent = (): JSX.Element => (
    <Styled.BoxStyled background={colors.color21}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: toRem(12), fontSize: toRem(28) }} />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.passed.total}% {RULE_SUMMARY_DASHBOARD.PASSED}
            </Styled.BoxTitleStyled>
            <Styled.CloseChecksButtonStyled
              isIncomplete={false}
              onClick={() => {
                setCloseCheckContext('Passed');
                setIsConfirmationModalOpen(true);
              }}
              disabled={
                hook.getDashboardDataLoading || hook.setChecksToClosedLoading || extractTotalChecksAmount(true) === 0
              }
              theme="secondary"
              type="button"
              loading={hook.setChecksToClosedLoading}
            >
              {RULE_SUMMARY_DASHBOARD.SET_CLOSE_CHECKS}
            </Styled.CloseChecksButtonStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            {Object.entries(hook.dashboardData.passed).map(([key, value]) => {
              if (key === 'total') return null;
              return (
                <Styled.BoxContentStyled key={`${key}-${value}`}>
                  {value} {camelCaseToSentenceCase(key)}
                </Styled.BoxContentStyled>
              );
            })}
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderFailedContent = (): JSX.Element => (
    <Styled.BoxStyled background={colors.color14}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon icon={faCircleXmark} style={{ marginRight: toRem(12), fontSize: toRem(28) }} />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.failed.total}% {RULE_SUMMARY_DASHBOARD.FAILED}
            </Styled.BoxTitleStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            {Object.entries(hook.dashboardData.failed).map(([key, value]) => {
              if (key === 'total') return null;
              return (
                <Styled.BoxContentStyled key={`${key}-${value}`}>
                  {value} {camelCaseToSentenceCase(key)}
                </Styled.BoxContentStyled>
              );
            })}
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderIncompleteContent = (): JSX.Element => (
    <Styled.BoxStyled background={colors.color12}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          {/* Incomplete */}
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: toRem(12), fontSize: toRem(28) }} />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.checks.urlsIncomplete}% {RULE_SUMMARY_DASHBOARD.INCOMPLETE}
            </Styled.BoxTitleStyled>
            <Styled.CloseChecksButtonStyled
              onClick={() => {
                setCloseCheckContext('Incomplete');
                setIsConfirmationModalOpen(true);
              }}
              disabled={
                hook.getDashboardDataLoading || hook.setChecksToClosedLoading || extractTotalChecksAmount(false) === 0
              }
              theme="secondary"
              type="button"
              loading={hook.setChecksToClosedLoading}
              isIncomplete
            >
              {RULE_SUMMARY_DASHBOARD.SET_CLOSE_CHECKS}
            </Styled.CloseChecksButtonStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            {Object.entries(hook.dashboardData.incomplete).map(([key, value]) => {
              if (key === 'total') return null;
              return (
                <Styled.BoxContentStyled key={`${key}-${value}`}>
                  {value} {camelCaseToSentenceCase(key)}
                </Styled.BoxContentStyled>
              );
            })}
          </Styled.BoxContentWrapperStyled>

          {!isMonitoringReport && (
            <>
              <Styled.SeparatorStyled />

              {/* Not Applicable */}
              <Styled.BoxTitleWrapperStyled>
                <FontAwesomeIcon icon={faBan} style={{ marginRight: toRem(12), fontSize: toRem(28) }} />
                <Styled.BoxTitleStyled>
                  {hook.dashboardData.checks.urlsNotApplicable}% {RULE_SUMMARY_DASHBOARD.NOT_APPLICABLE}
                </Styled.BoxTitleStyled>
              </Styled.BoxTitleWrapperStyled>

              <Styled.BoxContentWrapperStyled style={{ paddingTop: '0.5rem' }}>
                {Object.entries(hook.dashboardData.notApplicable).map(([key, value]) => {
                  if (key === 'total') return null;
                  return (
                    <Styled.BoxContentStyled key={`${key}-${value}`}>
                      {value} {camelCaseToSentenceCase(key)}
                    </Styled.BoxContentStyled>
                  );
                })}
              </Styled.BoxContentWrapperStyled>
            </>
          )}
        </>
      )}
    </Styled.BoxStyled>
  );

  if (!hook.dashboardData) return null;

  return (
    <Styled.WrapperStyled>
      <Styled.RefreshButtonStyled
        isLoading={hook.getDashboardDataLoading}
        onClick={() => hook.fetchDashboardData()}
        disabled={hook.getDashboardDataLoading}
        theme="text-only"
        type="button"
      >
        {hook.getDashboardDataLoading ? (
          <Styled.RefreshIconStyled
            icon={faRotateRight}
            style={{
              transition: 'transform 0.3s ease',
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faRotateRight}
            style={{
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              fontSize: toRem(20),
            }}
          />
        )}
      </Styled.RefreshButtonStyled>

      <Styled.ContentStyled>
        {/* ----------- Checks Dashboard ----------- */}
        {renderChecksContent()}
        {/* ----------- Passed Dashboard ----------- */}
        {renderPassedContent()}
        {/* ----------- Failed Dashboard ----------- */}
        {renderFailedContent()}
        {/* ----------- Incomplete Dashboard ----------- */}
        {renderIncompleteContent()}
      </Styled.ContentStyled>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={
          // eslint-disable-next-line prettier/prettier, prefer-template
          RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.DESCRIPTION_START +
          extractTotalChecksAmount(closeCheckContext === 'Passed') +
          ' ' +
          closeCheckContext +
          RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.DESCRIPTION_END
        }
        isLoading={hook.setChecksToClosedLoading}
        onConfirm={async () => {
          if (closeCheckContext === '') return;
          await hook.closeAllChecks(closeCheckContext);
          setIsConfirmationModalOpen(false);
        }}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        onCloseText={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.CANCEL}
        onConfirmText={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.CONFIRM}
        width="35vw"
      />
    </Styled.WrapperStyled>
  );
};

export default RulesSummaryDashboard;
