export const securityMap = {
  admin: {
    dashboard: 'dashboard',
    merchants: 'merchants',
    ads: 'ads',
    fintelCheck: 'fintelCheck',
    payments: 'payments',
    messageHub: 'messageHub',
    publishers: 'publishers',
    reports: 'reports',
    transactions: 'transactions',
    settings: 'settings',
    adsManagement: 'ads.adManagement',
    adsSearch: 'ads.search',
    adEdit: 'ad.edit',
    adsCampaignManagement: 'ads.campaignManagement',
    fintelCheckPlans: 'fintelCheck.plan',
    fintelCheckLinkManager: 'fintelCheck.linkManager',
    fintelCheckNewSiteMonitoring: 'fintelCheck.newSiteMonitoring',
    fintelCheckAdminCheckReport: 'fintelCheck.fintelCheckReport',
    fintelMonitoringRulesReport: 'fintelCheck.monitoringReport',
    fintelEvaluationRulesReport: 'fintelCheck.evaluationReport',
    merchantChangeMonitoring: 'merchantChangeMonitoring',
    paymentsGeneratePayments: 'payments.generatePayments',
    paymentsEstimatePayments: 'payments.estimatePayments',
    paymentsProcessPayments: 'payments.processPayments',
    paymentsPayPayments: 'payments.payPayments',
    paymentsApprovePayments: 'payments.approvePayments',
    paymentsPaymentReport: 'payments.paymentReport',
    paymentsCurrencyRates: 'payments.currencyRates',
    paymentsManualPublisherBonuses: 'payments.manualPublisherBonuses',
    paymentsAccountBalance: 'payments.accountBalance',
    reportsPerformanceReport: 'reports.performanceReport',
    reportsTransactionMaster: 'reports.transactionMaster',
    reportsManageSavedReports: 'reports.manageSavedReports',
    reportsEditSavedReports: 'reports.editSavedReports',
    transactionsPendingTransactions: 'transactions.pendingTransactions',
    transactionsAddTransactions: 'transactions.addTransactions',
    settingsUserManagement: 'settings.userManagement',
    settingsPublisherInvites: 'settings.publisherInvites',
    settingsSecurityPermissions: 'settings.securityPermissions',
    userProfileManagement: 'userProfileManagement',
  },
  merchant: {
    merchantDashboard: 'merchantDashboard',
    merchantAccount: 'merchantAccount',
    merchantCommissions: 'merchantCommissions',
    merchantPublishers: 'merchantPublishers',
    merchantAds: 'merchantAds',
    merchantFintelCheck: 'merchantFintelCheck',
    merchantTransactions: 'merchantTransactions',
    merchantMessageHub: 'merchantMessageHub',
    merchantAccountAccountDetails: 'merchantAccount.accountDetails',
    merchantAccountTrackingTransactionSettings: 'merchantAccount.trackingTransactionSettings',
    merchantAccountAccountBalance: 'merchantAccount.accountBalance',
    merchantAccountProductCatalogue: 'merchantAccount.productCatalogue',
    merchantAccountUserManagement: 'merchantAccount.userManagement',
    merchantPostbackDetails: 'merchantAccount.postbackDetails',
    merchantPublishersMembershipManagement: 'merchantPublishers.membershipManagement',
    merchantPublishersPublisherInvitations: 'merchantPublishers.publisherInvitations',
    merchantPublishersPublisherSearch: 'merchantPublishers.publishersSearch',
    merchantPublishersPublisherGroup: 'merchantPublishers.publishersGroup',
    merchantPublishersInternalPublisher: 'merchantPublishers.internalPublisher',
    merchantAdsManagement: 'merchantAds.adManagement',
    merchantAdEdit: 'merchantAds.adEdit',
    merchantAdSearch: 'merchantAds.search',
    merchantAdsCampaignManagement: 'merchantAds.campaignManagement',
    merchantFintelCheckBlacklist: 'merchantFintelCheck.blacklist',
    merchantFintelCheckFintelCheckReport: 'merchantFintelCheck.fintelCheckReport',
    merchantFintelCheckRuleManager: 'merchantFintelCheck.ruleManager',
    merchantFintelCheckLinkManager: 'merchantFintelCheck.linkManager',
    merchantFintelCheckNewSiteMonitoring: 'merchantFintelCheck.newSiteMonitoring',
    merchantFintelCheckFintelCheckingSettings: 'merchantFintelCheck.fintelCheckSettings',
    merchantFintelCheckPublisherList: 'merchantFintelCheck.publisherList',
    merchantFintelCheckHistory: 'merchantFintelCheck.history',
    merchantFintelCheckChangeMonitoring: 'merchantFintelCheck.changeMonitoring',
    merchantFintelMonitoringRulesReport: 'merchantFintelCheck.monitoringReport',
    merchantFintelEvaluationRulesReport: 'merchantFintelCheck.evaluationReport',
    merchantFintelCheckManualUrls: 'merchantFintelCheck.manualUrls',
    merchantFintelCheckChangeMonitoringDetails: 'merchantFintelCheck.changeMonitoringDetails',
    merchantTransactionsPendingTransactions: 'merchantTransactions.pendingTransactions',
    merchantTransactionsAddTransactions: 'merchantTransactions.addTransactions',
    merchantReportsPerformanceReport: 'merchantReports.performanceReport',
    merchantReportsTransactionMaster: 'merchantReports.transactionMaster',
    merchantReportsManageSavedReports: 'merchantReports.manageSavedReports',
    merchantReportsEditSavedReports: 'merchantReports.editSavedReports',
    merchantReportsPaymentReport: 'merchantReports.paymentReport',
    merchantReports: 'merchantReports',
    merchantAccountProgramSettings: 'merchantAccount.programSettings',
  },
  publisher: {
    publisherDashboard: 'publisherDashboard',
    publisherAccount: 'publisherAccount',
    publisherOverview: 'publisherOverview',
    publisherMerchants: 'publisherMerchants',
    publisherCommissions: 'publisherCommissions',
    publisherAds: 'publisherAds',
    publisherReports: 'publisherReports',
    publisherFintelMonitor: 'publisherFintelMonitor',
    publisherMessageHub: 'publisherMessageHub',
    publisherAccountAccountDetails: 'publisherAccount.accountDetails',
    publisherAccountTrackingDetails: 'publisherAccount.trackingDetails',
    publisherAccountUserManagement: 'publisherAccount.userManagement',
    publisherPostbackDetails: 'publisherAccount.postbackDetails',
    publisherMerchantsMerchantManagement: 'publisherMerchants.merchantManagement',
    publisherMerchantsSearchPrograms: 'publisherMerchants.searchPrograms',
    publisherAdsAdSearch: 'publisherAds.adSearch',
    publisherAdsProductFeed: 'publisherAds.productFeed',
    publisherReportsTransactionMaster: 'publisherReports.transactionMaster',
    publisherReportsPerformanceReport: 'publisherReports.performanceReport',
    publisherReportsPaymentReport: 'publisherReports.paymentReport',
    publisherReportsManageSavedReport: 'publisherReports.manageSavedReport',
    publisherReportsEditSavedReport: 'publisherReports.editSavedReport',
    publisherFintelMonitorLinkManager: 'publisherFintelCheckMonitor.linkManager',
  },
};
