import { some } from 'lodash';
import { useContext } from 'react';

import { useCompanyFeatures, useUserInfo } from '../../../hooks';
import { securityMap, USER_TYPES_ID } from '../../../utils';
import { SideMenuContext } from '../SideMenu.context';
import { ISideMenuItem, ISideMenuState } from '../types';
import { admin, merchant, publisher } from '../utils';

interface IUseSideMenuHook {
  sideMenuState: ISideMenuState;
  getSideMenu: () => ISideMenuItem[];
  toggleSideMenuItem: (itemId: string | number, level: number, collapsable: boolean) => void;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  isSideMenuItemAvailable: (sideMenuItem: ISideMenuItem) => boolean;
  isSideMenuItemOpened: (key: number, value: string | number) => boolean;
  isUnderManagement: (targetId: string) => boolean;
}

export const useSideMenu = (): IUseSideMenuHook => {
  const { isChangeMonitoringEnabled } = useCompanyFeatures();
  const { hookGetPermissionByCode, hookUserInfo } = useUserInfo();

  const context = useContext(SideMenuContext);

  if (context === undefined) {
    throw new Error('useSideMenu must be used within a SideMenuProvider');
  }

  const { isCollapsed, sideMenuState, toggleSideMenuItem, toggleCollapse } = context;

  /**
   * Get side menu items based on the role type
   */
  const getSideMenu = (): ISideMenuItem[] => {
    const menuMap = {
      [USER_TYPES_ID.ADMIN]: admin,
      [USER_TYPES_ID.MERCHANT]: merchant,
      [USER_TYPES_ID.PUBLISHER]: publisher,
    };

    return menuMap[hookUserInfo.userTypesId] || [];
  };

  /**
   * Check if the side menu item is available based on the user permissions
   */
  const isSideMenuItemAvailable = (sideMenuItem: ISideMenuItem): boolean => {
    if (sideMenuItem.key === securityMap.merchant.merchantFintelCheckChangeMonitoring && !isChangeMonitoringEnabled()) {
      return false;
    }

    const checkPermission = (key: string): boolean => hookGetPermissionByCode(key).permission !== 'noAccess';

    return Array.isArray(sideMenuItem.key) ? sideMenuItem.key.some(checkPermission) : checkPermission(sideMenuItem.key);
  };

  /**
   * Check if the side menu item is opened
   */
  const isSideMenuItemOpened = (key: number, value: string | number): boolean =>
    sideMenuState[key] ? sideMenuState[key] === value : false;

  const isUnderManagement = (targetId: string): boolean => {
    const currentMenu = getSideMenu();

    const checkItem = (items: ISideMenuItem[], path: ISideMenuItem[] = []): boolean => {
      return some(items, (item) => {
        const newPath = [...path, item];

        if (item.id === targetId) {
          // Check if any item in the path has a management field
          return some(newPath, (pathItem) => !!pathItem.management);
        }

        return item.subSections ? checkItem(item.subSections, newPath) : false;
      });
    };

    return checkItem(currentMenu);
  };

  return {
    sideMenuState,
    getSideMenu,
    toggleSideMenuItem,
    toggleCollapse,
    isCollapsed,
    isSideMenuItemAvailable,
    isSideMenuItemOpened,
    isUnderManagement,
  };
};
