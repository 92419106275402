import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast, useUserInfo } from 'hooks';
import { CloseChecksTypes } from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/enums';
import { GET_SUMMARY_DASHBOARD_DATA } from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/graphql/getSummaryDashboardData';
import { UPDATE_PASSED_CHECKS_TO_CLOSED } from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/graphql/updatePassedChecksToClosed';
import {
  EmptySummaryDashboardData,
  TSummaryDashboardData,
} from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard/types';
import { useState, useEffect } from 'react';

const useRuleSummaryDashboard = ({
  isMonitoringReport = true,
}: {
  isMonitoringReport: boolean;
}): {
  dashboardData: TSummaryDashboardData;
  fetchDashboardData: () => Promise<void>;
  getDashboardDataLoading: boolean;
  closeAllChecks: (closeCheckContext: CloseChecksTypes) => Promise<void>;
  setChecksToClosedLoading: boolean;
} => {
  const [dashboardData, setDashboardData] = useState<TSummaryDashboardData>(EmptySummaryDashboardData);
  const { hookWhoAmI } = useUserInfo();
  const toast = useToast();
  const [getDashboardData, { loading: getDashboardDataLoading }] = useLazyQuery(GET_SUMMARY_DASHBOARD_DATA);
  const [setChecksToClosed, { loading: setChecksToClosedLoading }] = useMutation(UPDATE_PASSED_CHECKS_TO_CLOSED);

  /* Loads Dashboard data and breakdown between Checks/Passed/Failed */
  const fetchDashboardData = async (): Promise<void> => {
    const { data, error } = await getDashboardData({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId,
          isMonitoringReport,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      toast.hookShowToast(error.message);
      return;
    }
    if (data) {
      setDashboardData(data.getSummaryDashboardData);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* This function sets all checks with state Passed to closed */
  const closeAllChecks = async (closeCheckContext: 'Passed' | 'Incomplete'): Promise<void> => {
    const { data, errors } = await setChecksToClosed({
      variables: {
        input: {
          userId: hookWhoAmI.id,
          merchantId: hookWhoAmI.companyId,
          isMonitoringReport,
          shouldClosePassed: closeCheckContext === 'Passed',
        },
      },
    });
    if (errors && errors?.length > 0) {
      toast.hookShowToast(errors[0].message);
    }
    if (data && data.updatePassedChecksToClosed) {
      await fetchDashboardData();
    }
  };

  return {
    dashboardData,
    fetchDashboardData,
    getDashboardDataLoading,
    closeAllChecks,
    setChecksToClosedLoading,
  };
};

export default useRuleSummaryDashboard;
