import { useLazyQuery, useMutation } from '@apollo/client';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useModal, useToast, useUserInfo } from '../../../../../../../hooks';
import { csvGenerator, paginator, URL_STATUSES, sortString } from '../../../../../../../utils';
import { useDebounce } from '../../../../../../../utils/useDebounce';
import { useValidation } from '../../../../../../../utils/validation';
import {
  CHECK_TYPES,
  CHECK_TYPES_OPTIONS,
  ManualUrlType,
  URLS_CATEGORIES,
  URLS_CATEGORIES_OPTIONS,
  URLS_LIST,
} from '../enums';
import { CREATE_MANUAL_URL } from '../graphql/mutations/createManualUrl';
import { REMOVE_BULK_MANUAL_URL } from '../graphql/mutations/removeBulkManualUrls';
import { REMOVE_MANUAL_URL } from '../graphql/mutations/removeManualUrl';
import { UPDATE_MANUAL_URL } from '../graphql/mutations/updateManualUrl';
import { GET_MANUAL_URL_LIST } from '../graphql/queries';
import { GET_MANUAL_URL_CSV } from '../graphql/queries/getManualUrlCSV';

type UseUrlsListReturnType = {
  loading: boolean;
  csvLoading: boolean;
  validateLoading: boolean;
  updateManualUrlLoading: boolean;
  createManualUrlLoading: boolean;
  removeManualUrlLoading: boolean;
  removeBulkManualUrlsLoading: boolean;

  search: string;
  setSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;

  tableData: ManualUrlType[];
  setTableData: React.Dispatch<React.SetStateAction<ManualUrlType[]>>;

  isOpen: boolean;
  setIsOpen: () => void;

  id: number;
  url: string;
  selectedUrls: string[];
  setUrl: (e: React.ChangeEvent<HTMLInputElement>) => void;
  urlError: string;
  onRowClick: (row: ManualUrlType) => void;
  category: SelectOption | undefined;
  setCategory: (category: SelectOption) => void;
  categoryError: string;
  checkType: SelectOption | undefined;
  setCheckType: (checkType: SelectOption) => void;
  checkTypeError: string;

  setModalInfo: ({ id, manualUrl, type, loadedCategory, loadedCheckType }: Record<string, string | number>) => void;
  modalType: string;
  resetModal: () => void;

  setPage: (page: number) => void;
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;

  handleSort: (dataField: string, direction: 'desc' | 'asc' | undefined) => void;
  sortColumn: TableSortColumn;

  handleGenerateCSV: () => void;

  handleRemoveButton: () => void;
  handleModalRightButton: () => Promise<void>;
};

export const useUrlsList = (isReadOnly: boolean): UseUrlsListReturnType => {
  const validator = useValidation();
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();

  // Table States
  const [tableData, setTableData] = useState<ManualUrlType[]>([]);
  const [urlsList, setUrlsList] = useState<ManualUrlType[]>([]);
  const [urlsTemp, setUrlsTemp] = useState<ManualUrlType[]>([]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: '', direction: 'asc' });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  // Modal States
  const [isOpen, setIsOpen] = useModal(false);
  const [modalType, setModalType] = useState(URLS_LIST.MODAL.TYPES.ADD);
  const [url, setUrl] = useState<string>('');
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
  const [urlStatus, setUrlStatus] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [ID, setID] = useState<number>(0);
  const [category, setCategory] = useState<SelectOption>(URLS_CATEGORIES_OPTIONS[0]);
  const [categoryError, setCategoryError] = useState<string>('');
  const [checkType, setCheckType] = useState<SelectOption>(CHECK_TYPES_OPTIONS[0]);
  const [checkTypeError, setCheckTypeError] = useState<string>('');

  // Page States
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [validateLoading, setValidateLoading] = useState<boolean>(false);

  const [getManualUrls, { loading: getManualUrlsLoading }] = useLazyQuery(GET_MANUAL_URL_LIST);
  const [getManualUrlCSV, { loading: getCSVLoading }] = useLazyQuery(GET_MANUAL_URL_CSV);
  const [updateManualUrl, { loading: updateManualUrlLoading }] = useMutation(UPDATE_MANUAL_URL);
  const [createManualUrl, { loading: createManualUrlLoading }] = useMutation(CREATE_MANUAL_URL);
  const [removeManualUrl, { loading: removeManualUrlLoading }] = useMutation(REMOVE_MANUAL_URL);
  const [removeBulkManualUrls, { loading: removeBulkManualUrlsLoading }] = useMutation(REMOVE_BULK_MANUAL_URL);

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const clearSearch = (): void => {
    setSearch('');
  };

  const setUrlHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUrl(e.target.value);
  };

  const validateUrl = useCallback(async (): Promise<string> => {
    const newStatus = await validator.validateUrlStatus(url, setUrlStatus, undefined, undefined, true);
    if (newStatus || urlStatus) validator.renderUrlCheck(newStatus, setUrlError);
    return newStatus;
  }, [url, urlStatus, validator]);

  /**
   *  Checks if category is valid (not empty)
   * @returns {boolean} - Returns true if category is valid, false otherwise
   */
  const isCategoryValid = (): boolean => {
    if (!category || category.value === '') {
      setCategoryError(URLS_LIST.ERROR.MISSING_CATEGORY);
      return false;
    }
    setCategoryError('');
    return true;
  };

  /**
   * Checks if check type is valid (not empty)
   * @returns {boolean} - Returns true if check type is valid, false otherwise
   */
  const isCheckTypeValid = (): boolean => {
    if (!checkType || checkType.value === '') {
      setCheckTypeError(URLS_LIST.ERROR.MISSING_CHECK_TYPE);
      return false;
    }
    setCheckTypeError('');
    return true;
  };

  /**
   * Sets the modal info based on the type of modal
   * @param {Object} - Object containing the following properties:
   * @param {string} id - ID of the manual URL
   * @param {string} manualUrl - URL of the manual URL
   * @param {string} type - Type of the modal (add, edit, remove, remove_many)
   * @param {string} loadedCategory - Category of the manual URL
   * @param {string} loadedCheckType - Check type of the manual URL
   */
  const setModalInfo = ({
    id,
    manualUrl,
    type = URLS_LIST.MODAL.TYPES.ADD,
    loadedCategory,
    loadedCheckType,
  }: Record<string, string | number>): void => {
    setModalType(type.toString());
    if (id) setID(Number(id));
    if (manualUrl) setUrl(manualUrl.toString());
    if (loadedCategory) {
      const cat = Object.entries(URLS_CATEGORIES).find(([_label, value]) => value === loadedCategory);
      if (cat) {
        const [value, label] = cat;
        setCategory({ label, value });
      }
    }
    if (loadedCheckType) {
      const check = Object.entries(CHECK_TYPES).find(([_label, value]) => value === loadedCheckType);
      if (check) {
        const [value, label] = check;
        setCheckType({ label, value });
      }
    }
  };

  const resetModal = (): void => {
    setID(0);
    setUrl('');
    setSelectedUrls([]);
    setCategory({
      label: URLS_CATEGORIES.MANUAL,
      value: URLS_CATEGORIES.MANUAL,
    });
    setCheckType({
      label: CHECK_TYPES.ALL_RULES,
      value: CHECK_TYPES.ALL_RULES,
    });
  };

  const setCurrentPageHandler = (page: number): void => {
    setCurrentPage(page);
  };

  const handleRowClick = (row: ManualUrlType): void => {
    if (isReadOnly) return;

    const index = tableData.findIndex((item) => item.url === row.url);
    const tableDataCopy = [...tableData];
    tableDataCopy[index].checked = !tableDataCopy[index].checked;
    setTableData(tableDataCopy);
  };

  // ===== Querying & Mutating Data in DB =====

  /**
   * Fetches the manual URLs from the database and sets the table data
   */
  const fetchManualUrls = useCallback(async (): Promise<void> => {
    try {
      const { data, error } = await getManualUrls({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (error) {
        hookShowToast(URLS_LIST.ERROR.GET_DATA_ERROR);
      }

      if (data?.getAllManualUrls?.manualUrlsList) {
        const formattedList = data.getAllManualUrls.manualUrlsList.map((item: ManualUrlType) => ({
          id: item.id,
          url: item.url,
          category: _.startCase(item.category.toLowerCase()),
          checkType: _.startCase(item.checkType.toLowerCase()),
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        }));
        setUrlsList(formattedList);
        setTableData(paginator(formattedList, recordsPerPage, 1));
        setTotalPages(Math.ceil(data.getAllManualUrls.manualUrlsList.length / recordsPerPage));
        setCurrentPage(1);
      } else {
        setCurrentPage(1);
        setTotalPages(1);
      }
    } catch (error) {
      hookShowToast(URLS_LIST.ERROR.GET_DATA_ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getManualUrls, hookWhoAmI?.companyId]);

  const handleGenerateCSV = async (): Promise<void> => {
    const { data } = await getManualUrlCSV({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getManualUrlCSV?.csv) {
      csvGenerator(data.getManualUrlCSV.csv, 'Manual Url List');
    }
  };

  // ===== Table changes handlers =====

  const handleSearch = useCallback((): void => {
    const urlsListToSet =
      debouncedSearch === ''
        ? urlsList
        : [...urlsList].filter((item) => item.url.toLowerCase().includes(debouncedSearch));
    setUrlsTemp(urlsListToSet);
    setTableData(paginator(urlsListToSet, recordsPerPage, 1));
    setTotalPages(Math.ceil(urlsListToSet.length / recordsPerPage));
    setCurrentPage(1);
  }, [debouncedSearch, urlsList]);

  const handlePageChange = (page: number): void => {
    const paginatedData = paginator(debouncedSearch ? urlsTemp : urlsList, recordsPerPage, page);
    setTableData(paginatedData);
    setCurrentPage(page);
  };

  const handleSort = (dataField: string, direction: 'desc' | 'asc' | undefined): void => {
    const directionString: string = direction === 'asc' ? 'asc' : 'desc';
    const copyArrayList = [...urlsList];
    const copyArray = debouncedSearch ? [...urlsTemp] : copyArrayList;
    const sortedArray = sortString(copyArray, dataField, directionString);
    const sortedArrayList = sortString(copyArrayList, dataField, directionString);
    setUrlsTemp(sortedArray);
    setUrlsList(sortedArrayList);
    setTableData(paginator(sortedArray, recordsPerPage, 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  /**
   * Updates the manual URL in the database and fetches the updated list
   * @returns {Promise<void>} - Returns a promise
   */
  const handleUpdateManualUrl = async (): Promise<void> => {
    const { data, errors } = await updateManualUrl({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          id: ID,
          url,
          category: category?.value,
          checkType: checkType?.value,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }
    if (data?.updateManualUrl) {
      await fetchManualUrls();
    }
  };

  const handleCreateManualUrl = async (): Promise<void> => {
    if (
      urlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
      urlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
      urlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS
    )
      return;

    if (!isCategoryValid() || !isCheckTypeValid()) return;

    const categoryValue = URLS_CATEGORIES_OPTIONS.find((item) => item.label === category?.label)?.value;
    const chekcTypeValue = CHECK_TYPES_OPTIONS.find((item) => item.label === checkType?.label)?.value;

    const { data, errors } = await createManualUrl({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          url,
          category: categoryValue,
          checkType: chekcTypeValue,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }
    if (data?.createManualUrl) {
      await fetchManualUrls();
      setIsOpen();
      resetModal();
    }
  };

  /**
   * Edits the manual URL in the database and fetches the updated list
   * Close and reset the modal
   * @returns {Promise<void>} - Returns a promise
   */
  const handleEditManualUrl = async (): Promise<void> => {
    if (
      urlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
      urlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
      urlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS
    )
      return;

    if (!isCategoryValid() || !isCheckTypeValid()) return;

    await handleUpdateManualUrl();

    setIsOpen();
    resetModal();
  };

  const handleRemoveManualUrl = async (): Promise<void> => {
    const { data, errors } = await removeManualUrl({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          id: ID,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }
    if (data?.removeManualUrl) {
      await fetchManualUrls();
      setIsOpen();
      resetModal();
    }
  };

  const handleRemoveBulkManualUrls = async (): Promise<void> => {
    const { data, errors } = await removeBulkManualUrls({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          urls: selectedUrls,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }
    if (data?.removeBulkManualUrls) {
      await fetchManualUrls();
      setIsOpen();
      resetModal();
    }
  };

  // ===== Button handlers =====

  const handleRemoveButton = (): void => {
    setIsOpen();
    setModalType(URLS_LIST.MODAL.TYPES.REMOVE_MANY);
  };

  const handleModalRightButton = async (): Promise<void> => {
    let newURLStatus = '';
    if (modalType === URLS_LIST.MODAL.TYPES.ADD || modalType === URLS_LIST.MODAL.TYPES.EDIT) {
      newURLStatus = await validateUrl();
    }
    switch (modalType) {
      case URLS_LIST.MODAL.TYPES.ADD:
        setValidateLoading(true);
        if (
          newURLStatus === URL_STATUSES.ACTIVE_WEBSITE.STATUS ||
          newURLStatus === URL_STATUSES.UNSAFE_WEBSITE.STATUS ||
          newURLStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS
        )
          handleCreateManualUrl();
        setValidateLoading(false);
        break;
      case URLS_LIST.MODAL.TYPES.EDIT:
        if (
          newURLStatus === URL_STATUSES.ACTIVE_WEBSITE.STATUS ||
          newURLStatus === URL_STATUSES.UNSAFE_WEBSITE.STATUS ||
          newURLStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS
        )
          handleEditManualUrl();
        break;
      case URLS_LIST.MODAL.TYPES.REMOVE:
        handleRemoveManualUrl();
        break;
      case URLS_LIST.MODAL.TYPES.REMOVE_MANY:
        handleRemoveBulkManualUrls();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (url === '' || url === undefined) setUrlError('');
    else if (modalType !== URLS_LIST.MODAL.TYPES.REMOVE && modalType !== URLS_LIST.MODAL.TYPES.REMOVE_MANY)
      validateUrl();
  }, [modalType, url, validateUrl]);

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch, handleSearch]);

  useEffect(() => {
    if (modalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY)
      setSelectedUrls(tableData.filter((item) => item.checked === true).map((item) => item.url));
  }, [modalType, isOpen, tableData]);

  useEffect(() => {
    fetchManualUrls();
    setCurrentPage(1);
  }, [fetchManualUrls]);

  return {
    loading: getManualUrlsLoading,
    csvLoading: getCSVLoading,
    validateLoading,
    createManualUrlLoading,
    updateManualUrlLoading,
    removeManualUrlLoading,
    removeBulkManualUrlsLoading,

    search,
    setSearch: setSearchHandler,
    clearSearch,

    tableData,
    setTableData,

    isOpen,
    setIsOpen,

    onRowClick: handleRowClick,

    id: ID,
    url,
    selectedUrls,
    setUrl: setUrlHandler,
    urlError,
    category,
    setCategory,
    categoryError,
    checkType,
    setCheckType,
    checkTypeError,

    setModalInfo,
    modalType,
    resetModal,

    setPage: setCurrentPageHandler,
    onPageChange: handlePageChange,
    currentPage,
    totalPages,

    handleSort,
    sortColumn,

    handleGenerateCSV,

    handleRemoveButton,
    handleModalRightButton,
  };
};
