import { faSearch, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper,
  Button,
  Calendar,
  ErrorBanner,
  InputCalendar,
  PageTitle,
  Pagination,
  RecordsDropdown,
  Table,
} from '../../../../components';
import PlaceHolderNoResults from '../../../../components/NoResultsPlaceHolder';
import { useCompanyFeatures } from '../../../../hooks';
import { DefaultPropsType } from '../../../../types';
import { RECORDS_PER_PAGE_OPTIONS, path } from '../../../../utils';

import ReviewStatus from './components/ReviewStatus';
import { REVIEW_STATUS } from './components/ReviewStatus/enums';
import { columns } from './contracts/columns';
import { CLEAR_FORM, DOWNLOAD_CSV, SET_STATUS_TITLE } from './enum';
import { useChangeMonitoring } from './hooks/useChangeMonitoring';
import * as Styled from './styles';

type ChangeMonitoringProps = DefaultPropsType;

const ChangeMonitoring = ({ permissionsCodeList = [] }: ChangeMonitoringProps): ReactElement | null => {
  const hook = useChangeMonitoring();
  const { isChangeMonitoringEnabled } = useCompanyFeatures();
  const navigate = useNavigate();

  if (!isChangeMonitoringEnabled()) {
    navigate('/merchant');
    return null;
  }

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.errorMessage} isOpen={hook.errorMessage.length > 0} />
      <Styled.HeaderStyled>
        <PageTitle>{path.fintelCheckChangeMonitoring.name}</PageTitle>
      </Styled.HeaderStyled>

      <Styled.FiltersStyled>
        <Styled.FiltersSelectStyled>
          <InputCalendar
            label="Change Date"
            placeholder="Select Date"
            value={hook.selectedRunDate ? hook.selectedRunDate.toDateString() : ''}
            onClick={() => hook.setIsDateCalendarOpen(true)}
            isDisabled={hook.isLoading}
          />

          <Styled.InputTextStyled
            faIcon={faSearch}
            type="text"
            placeholder="Search for URL..."
            label="URL Search"
            value={hook.urlSearch}
            onChange={(e) => hook.onUrlSearchChange(e.target.value)}
            disabled={hook.isLoading}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !hook.isLoading) hook.searchButtonHandler();
            }}
          />

          <Styled.SelectStyled
            options={[{ label: 'All Statuses', value: 'All Statuses' }, ...REVIEW_STATUS]}
            onChange={(e) => hook.setFilterReviewStatus(e)}
            selected={hook.filterReviewStatus}
            placeholder="Review Status"
            label="Review Status"
          />

          <Styled.ClearFormButtonStyled
            onClick={hook.handleOnClearFormData}
            type="reset"
            theme="text-only"
            disabled={hook.isLoading}
          >
            {CLEAR_FORM}
          </Styled.ClearFormButtonStyled>
        </Styled.FiltersSelectStyled>

        <Styled.RowStyled>
          <RecordsDropdown
            selected={hook.records}
            onChange={hook.setRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            totalRecords={hook.totalRecords}
            currentPage={hook.currentPage}
          />
          <Button onClick={hook.generateCSVHandler} theme="tertiary">
            <Styled.IconStyled icon={faFileArrowDown} />
            {DOWNLOAD_CSV}
          </Button>
        </Styled.RowStyled>
      </Styled.FiltersStyled>

      {hook.changeMonitoringData.length > 0 || hook.isLoading ? (
        <>
          <Table
            columns={columns(hook.handleOnViewChangesClick, hook.handleOnSelectCheckbox)}
            data={hook.changeMonitoringData}
            isLoading={hook.isLoading}
            loadWithData={hook.changeMonitoringData.length > 0}
            onSort={(column, direction) => hook.onSortHandler(column, direction)}
            sortColumn={hook.sortColumn}
            headerCheckBox={hook.headerCheckbox}
            handleHeaderCheckBox={(checked) => hook.handleOnSelectCheckbox(checked, undefined, true)}
          />
          {hook.changeMonitoringData.length > 0 && (
            <Pagination
              currentPage={hook.currentPage}
              total={hook.totalPages}
              onPageChange={hook.handleOnChangeCurrentPage}
            />
          )}
        </>
      ) : (
        <PlaceHolderNoResults />
      )}

      <Calendar
        date={hook.selectedRunDate}
        onApply={(date) => hook.handleOnSelectDate(date)}
        onCancel={() => hook.setIsDateCalendarOpen(false)}
        isOpen={hook.isDateCalendarOpen}
        hasRange={false}
        isSingle
      />

      {hook.changeMonitoringData.some((item) => item.checked) && (
        <Styled.SetStatusWrapper>
          <Styled.SubtitleStyled>{SET_STATUS_TITLE}</Styled.SubtitleStyled>
          <ReviewStatus
            selectedReviewStatus={hook.selectedReviewStatus}
            setReviewStatusNote={hook.setReviewStatusNote}
            reviewStatusNote={hook.reviewStatusNote}
            shouldDisableSetReviewStatusButton={hook.shouldDisableSetReviewStatusButton}
            setReviewStatusLoading={hook.setReviewStatusLoading}
            setSelectedReviewStatus={hook.setSelectedReviewStatus}
            onClickAction={() =>
              hook.selectedReviewStatus && hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value)
            }
          />
        </Styled.SetStatusWrapper>
      )}
    </AppWrapper>
  );
};

export default ChangeMonitoring;
