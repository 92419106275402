import React from 'react';

import { AppWrapper, PageTitle, Tabs } from 'components';
import { DefaultPropsType } from 'types';
import { path } from 'utils';

import * as Styled from './styles';
import FintelCheckUrlBulkUpload from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/Tabs/BulkUpload';
import FintelCheckUrlsList from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/Tabs/UrlsList';
import { useManualUrls } from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/hooks';

type FintelCheckManualUrlsProps = DefaultPropsType;

const FintelCheckManualUrls = ({ permissionsCodeList = [] }: FintelCheckManualUrlsProps): JSX.Element => {
  const { hookIsReadOnlyList } = useManualUrls(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.fintelCheckManualUrlsList.name}</PageTitle>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={['URLs List', 'Bulk Upload']}
        elements={[
          <FintelCheckUrlsList key="URLs List" isReadOnly={isReadOnly} />,
          <FintelCheckUrlBulkUpload key="Bulk Upload" isReadOnly={isReadOnly} />,
        ]}
      />
    </AppWrapper>
  );
};

export default FintelCheckManualUrls;
