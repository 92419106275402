import { faFileArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button, FixedFooter, InputText, Pagination, ResizeTable } from 'components'

import { columns } from './contracts'
import { URLS_LIST } from './enums'
import { useUrlsList } from './hooks'
import UrlModal from './Modal'
import * as Styled from './styles'

type FintelCheckUrlsListProps = {
  isReadOnly: boolean
}

const FintelCheckUrlsList = ({ isReadOnly }: FintelCheckUrlsListProps): JSX.Element => {
  const hook = useUrlsList(isReadOnly)

  return (
    <>
      <Styled.HeaderStyled>
        <Styled.FilterWrapperStyled>
          <InputText
            faIcon={faSearch}
            type="url"
            placeholder={URLS_LIST.PLACEHOLDERS.INPUT}
            value={hook.search}
            onChange={hook.setSearch}
          />
          <Styled.ButtonStyled theme="text-only" onClick={hook.clearSearch}>
            {URLS_LIST.BUTTONS.CLEAR}
          </Styled.ButtonStyled>
        </Styled.FilterWrapperStyled>

        <Styled.ButtonsWrapperStyled>
          <Styled.ButtonStyled
            onClick={() => {
              hook.setIsOpen()
              hook.setModalInfo({ type: URLS_LIST.MODAL.TYPES.ADD })
            }}
            disabled={isReadOnly}
          >
            {URLS_LIST.BUTTONS.ADD}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={hook.handleGenerateCSV}
            loading={hook.csvLoading}
            disabled={hook.csvLoading}
          >
            <Styled.IconStyled icon={faFileArrowDown} />
            {URLS_LIST.BUTTONS.DOWNLOAD}
          </Styled.ButtonStyled>
        </Styled.ButtonsWrapperStyled>
      </Styled.HeaderStyled>
      <>
        <ResizeTable
          columns={columns(isReadOnly)}
          data={hook.tableData}
          onSort={hook.handleSort}
          setOpenModal={hook.setIsOpen}
          setModalInfo={hook.setModalInfo}
          sortColumn={hook.sortColumn}
          onRowClick={hook.onRowClick}
          isLoading={hook.loading}
        />
        {hook.tableData.length > 0 && (
          <Styled.PaginationWrapper>
            <Pagination currentPage={hook.currentPage} total={hook.totalPages} onPageChange={hook.onPageChange} />
          </Styled.PaginationWrapper>
        )}
      </>
      <FixedFooter>
        <Button
          theme="tertiary"
          onClick={hook.handleRemoveButton}
          disabled={hook.tableData.filter((item) => item.checked).length < 1 || isReadOnly}
        >
          {URLS_LIST.BUTTONS.REMOVE}
        </Button>
      </FixedFooter>

      <UrlModal hook={hook} />
    </>
  )
}

export default FintelCheckUrlsList
