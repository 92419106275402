import React from 'react';

import { IndexedObject } from '../AddMonitoringRuleModal/types';

import {
  RuleStatusFieldStateOptions,
  RuleStatusFieldsRequiredOptions,
  RuleStatusRuleStateOptions,
  RuleStatusSettingEnum,
  RuleStatusSettingsType,
} from './enums';
import * as Styled from './styles';

type RuleStatusSettingsComponentProps = {
  ruleStatusSettings: RuleStatusSettingsType;
  setRuleStatusSettings: (value: RuleStatusSettingsType) => void;
  errors: IndexedObject;
};

const RuleStatusSettingsComponent = ({
  ruleStatusSettings,
  setRuleStatusSettings,
  errors,
}: RuleStatusSettingsComponentProps): JSX.Element => (
  <Styled.RuleStatusSettingsWrapper>
    <Styled.DescriptionSettingsText>{RuleStatusSettingEnum.IF}</Styled.DescriptionSettingsText>
    <Styled.RuleStatusSettingsSelect
      selected={{ label: ruleStatusSettings.fieldsRequired || '', value: ruleStatusSettings.fieldsRequired || '' }}
      onChange={(value) => setRuleStatusSettings({ ...ruleStatusSettings, fieldsRequired: value.value })}
      options={RuleStatusFieldsRequiredOptions}
      error={errors.fieldsRequired}
      required
    />
    <Styled.DescriptionSettingsText>{RuleStatusSettingEnum.TEXT_VALUES}</Styled.DescriptionSettingsText>
    <Styled.RuleStatusSettingsSelect
      selected={{ label: ruleStatusSettings.fieldState || '', value: ruleStatusSettings.fieldState || '' }}
      onChange={(value) => setRuleStatusSettings({ ...ruleStatusSettings, fieldState: value.value })}
      options={RuleStatusFieldStateOptions}
      error={errors.fieldState}
      required
    />
    <Styled.DescriptionSettingsText>{RuleStatusSettingEnum.THIS_RULE_SHOULD}</Styled.DescriptionSettingsText>
    <Styled.RuleStatusSettingsSelect
      selected={{ label: ruleStatusSettings.ruleState || '', value: ruleStatusSettings.ruleState || '' }}
      onChange={(value) => setRuleStatusSettings({ ...ruleStatusSettings, ruleState: value.value })}
      options={RuleStatusRuleStateOptions}
      error={errors.ruleState}
      required
    />
  </Styled.RuleStatusSettingsWrapper>
);

export default RuleStatusSettingsComponent;
