import { gql } from '@apollo/client';

export const GET_RULE_REPORT_FILTER_OPTIONS = gql`
  query getRuleReportFilters($input: RuleReportFiltersInput) {
    getRuleReportFilters(input: $input) {
      rules {
        label
        value
      }
      ruleGroups {
        label
        value
      }
      publishers {
        label
        value
      }
      productCategory {
        label
        value
      }
      products {
        label
        value
      }
    }
  }
`;
