import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button, Modal, ErrorBanner } from '../../../../../../components';
import { CheckMonitoringRulesOutputType } from '../../types';

import { RULE_SUMMARY, NEW_RULE_SUMMARY, FIELD_LABELS } from './enums';
import * as Styled from './styles';

type RuleDetailsModalProps = {
  value?: CheckMonitoringRulesOutputType;
  closeModalFn: () => void;
  isOpen: boolean;
  errorMessage: string;
  parametersOpen?: boolean;
  isNewRule?: boolean;
  goBackFn?: () => void;
  createRuleFn?: () => void;
  createRuleLoading?: boolean;
};

export const MonitoringRuleSummaryModal = ({
  value,
  closeModalFn,
  isOpen,
  errorMessage,
  parametersOpen,
  isNewRule,
  goBackFn,
  createRuleFn,
  createRuleLoading,
}: RuleDetailsModalProps) => {
  const ENUM: Partial<typeof NEW_RULE_SUMMARY> = isNewRule ? NEW_RULE_SUMMARY : RULE_SUMMARY;

  const formatRuleStatusSummary = () =>
    `If ${value?.ruleStatusSettings.fieldsRequired?.toLowerCase()} text phrase(s) are ${value?.ruleStatusSettings.fieldState?.toLowerCase()} on this page, this rule should ${value?.ruleStatusSettings.ruleState?.toLowerCase()}`;
  return (
    <Modal isOpen={isOpen && !parametersOpen}>
      <Styled.ModalWrapperStyled>
        <ErrorBanner isOpen={!!errorMessage} message={errorMessage} />
        <Styled.TitleRow>
          <Styled.TitleText>
            {isNewRule && (
              <Styled.BackIconStyled onClick={goBackFn}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Styled.BackIconStyled>
            )}
            {ENUM.TITLE}
          </Styled.TitleText>
          {!isNewRule && (
            <Button onClick={closeModalFn} theme="secondary">
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Styled.TitleRow>
        {isNewRule && <Styled.ReviewTextStyled>{ENUM?.REVIEW_NOTICE}</Styled.ReviewTextStyled>}
        <Styled.ConfirmRow2>
          <Styled.ConfirmRow2Elements>
            <Styled.CategoryWrapper>
              <Styled.CategoryHeader>{FIELD_LABELS.RULE_NAME}</Styled.CategoryHeader>
              <Styled.CategoryValue>{value?.ruleName}</Styled.CategoryValue>
            </Styled.CategoryWrapper>
          </Styled.ConfirmRow2Elements>
          <Styled.ConfirmRow2Elements>
            <Styled.CategoryWrapper>
              <Styled.CategoryHeader>{FIELD_LABELS.RULE_GROUP}</Styled.CategoryHeader>
              <Styled.CategoryValue>{value?.ruleGroup}</Styled.CategoryValue>
            </Styled.CategoryWrapper>
          </Styled.ConfirmRow2Elements>
        </Styled.ConfirmRow2>

        <Styled.RuleStatusWrapper>
          <Styled.ConfirmRow2Elements>
            <Styled.CategoryRuleStatusWrapper>
              <Styled.CategoryHeader>{FIELD_LABELS.RULE_STATUS}</Styled.CategoryHeader>
              <Styled.CategoryValue>{formatRuleStatusSummary()}</Styled.CategoryValue>
            </Styled.CategoryRuleStatusWrapper>
          </Styled.ConfirmRow2Elements>
        </Styled.RuleStatusWrapper>

        <Styled.CustomTextWrapper>
          <Styled.CustomTextListHeader>{FIELD_LABELS.CUSTOM_TEXT_LIST}</Styled.CustomTextListHeader>
          <Styled.CustomTextValuesWrapper>
            {value?.customTextList?.map((item: string, index: number) => (
              <Styled.CustomTextValue key={index}>
                <Styled.CTSpanIndex>{index + 1}</Styled.CTSpanIndex>
                <Styled.CTItemSpan>{item}</Styled.CTItemSpan>
              </Styled.CustomTextValue>
            ))}
          </Styled.CustomTextValuesWrapper>
        </Styled.CustomTextWrapper>

        {isNewRule && (
          <Styled.NavButtonsWrapperStyled>
            <Button
              theme="primary"
              onClick={() => {
                if (createRuleFn) createRuleFn();
              }}
              width="120px"
              loading={createRuleLoading}
            >
              {ENUM.CREATE_RULE_BUTTON}
            </Button>
            <Button theme="secondary" onClick={closeModalFn} width="100px">
              {ENUM.CANCEL_BUTTON}
            </Button>
          </Styled.NavButtonsWrapperStyled>
        )}
      </Styled.ModalWrapperStyled>
    </Modal>
  );
};
