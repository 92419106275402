import { RuleStatusSettingsType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/RuleStatusSettingsComponent/enums';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';
import React from 'react';
import { SnapshotFrom } from 'xstate';
import { Spacer } from 'components';

import * as Styled from '../styles';

export const TextSummary = ({ stateMachine }: { stateMachine: SnapshotFrom<typeof machine> }): JSX.Element => {
  const formatRuleStatusSummary = (ruleStatusSettings: RuleStatusSettingsType): string =>
    `If ${ruleStatusSettings.fieldsRequired?.toLowerCase()} 
      text phrase(s) are ${ruleStatusSettings.fieldState?.toLowerCase()} on this page, 
      this rule should ${ruleStatusSettings.ruleState?.toLowerCase()}
  `;

  return (
    <>
      <Styled.SubTitleTextStyled>Rule Eligibility</Styled.SubTitleTextStyled>
      <Spacer size="medium" direction="vertical" />
      <Styled.HeaderInfoText>
        These are the minimum requirements for a URL to be eligible for the rule to be applied
      </Styled.HeaderInfoText>
      <Spacer size="medium" direction="vertical" />

      <Styled.FlexRow>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Brand Name</Styled.TextFieldLabel>
          <Styled.TextFieldLabelDisclaimer>This name must appear on the page</Styled.TextFieldLabelDisclaimer>
          <Styled.TextFieldValue>{stateMachine.context.brandName}</Styled.TextFieldValue>
        </Styled.FlexColumn>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Text Value</Styled.TextFieldLabel>
          <Styled.TextFieldLabelDisclaimer>
            At least one of these must appear on the page
          </Styled.TextFieldLabelDisclaimer>
          <Styled.TextMatchWrapper>
            {stateMachine.context.textEligibility.length > 0 ? (
              stateMachine.context.textEligibility.map((item) => (
                <Styled.TextFieldValue key={item.id}>{item.value}</Styled.TextFieldValue>
              ))
            ) : (
              <Styled.TextFieldValue>Not Applicable</Styled.TextFieldValue>
            )}
          </Styled.TextMatchWrapper>
        </Styled.FlexColumn>
      </Styled.FlexRow>

      <Spacer size="large" direction="vertical" />

      <Styled.SubTitleTextStyled>Rule Criteria</Styled.SubTitleTextStyled>
      <Styled.TextDescription>
        {formatRuleStatusSummary(stateMachine.context.ruleStatusSettings)}
      </Styled.TextDescription>

      <Spacer size="medium" direction="vertical" />

      {stateMachine.context.textCriteria.map((item, index) => (
        <Styled.FlexColumn key={item.id}>
          <Styled.FlexRow>
            <Styled.TextFieldValue>{index + 1}</Styled.TextFieldValue>
            <Spacer size="xsmall" direction="horizontal" />
            <Styled.TextFieldValue>{item.value}</Styled.TextFieldValue>
          </Styled.FlexRow>
          <Spacer size="small" direction="vertical" />
        </Styled.FlexColumn>
      ))}
    </>
  );
};
