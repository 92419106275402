import { gql } from '@apollo/client';

export const GET_MONITORING_DATA = gql`
  query changeMonitoringData($input: ChangeMonitoringInput!) {
    changeMonitoringData(input: $input) {
      monitoringChanges {
        id
        runDate
        url
        pageDifferences
        changeSummary
        status
        statusChanges
        reviewer
        reviewDate
        crawlStatus
      }
      count
      currentPage
      totalPages
    }
  }
`;

export const SET_REVIEW_STATUS = gql`
  mutation changeMonitoringSetReviewStatus($input: ChangeMonitoringSetReviewStatusInput!) {
    changeMonitoringSetReviewStatus(input: $input)
  }
`;

export type ChangeMonitoringInput = {
  input: {
    merchantId: string;
    urlSearch: string | undefined;
    runDate: string | undefined;
    status: string | undefined;
    limit: number;
    currentPage: number;
    sortBy: string;
    sortDirection: string;
  };
};

export type ChangeMonitoringType = {
  id: string;
  runDate: string;
  url: string;
  pageDifferences: string;
  changeSummary: string;
  checked: boolean;
  status: string;
  statusChanges: string;
  reviewer: string;
  reviewDate: Date;
  crawlStatus: string;
};

export type ChangeMonitoringOutput = {
  changeMonitoringData: {
    monitoringChanges: ChangeMonitoringType[];
    count: number;
    currentPage: number;
    totalPages: number;
  };
};
