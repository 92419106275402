export const REVIEW_STATUS = [
  { label: 'New', value: 'NEW' },
  { label: 'In Process', value: 'IN_PROCESS' },
  { label: 'Reviewed', value: 'REVIEWED' },
];

export const CRAWL_STATUS = [
  { label: 'Changed', value: 'CHANGED' },
  { label: 'No Change', value: 'NO_CHANGE' },
  { label: 'Initial Entry', value: 'INITIAL_ENTRY' },
];
